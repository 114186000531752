<main class="main main-background">
  <section class="h-full bg-grey-100 w-3/12 overflow-scroll">
    <img src="../../assets/img/logo.png" class="ml-8 mt-8">
    <h1 class="my-2 mx-8 overline-o1 primary-text">{{'login.accessData' | translate }}</h1>
    <hr class="divider w-full">

    <form *ngIf="inviteConfirmationForm" [formGroup]="inviteConfirmationForm" (ngSubmit)="setPasswordNewUserFromOrganization(inviteConfirmationForm)" class="px-8 overflow-y-auto content-height overflow-x-hidden relative pb-10">
      <app-input [type]="'text'" [control]="inviteConfirmationForm.get('name')" [label]="('login.name' | translate) + '*'" [placeholder]="'login.name' | translate"></app-input>

      <app-input [type]="'text'" [control]="inviteConfirmationForm.get('email')" [label]="('login.email' | translate) + '*'" class="mt-2"></app-input>

      <app-input [type]="'password'" [control]="inviteConfirmationForm.get('password')" [label]="('login.pass' | translate) + '*'" class="mt-2"></app-input>

      <small class="flex flex-col mb-2 caption-c2 secondary-text">
          <span>
            {{ 'login.sixCharacters' | translate }}
          </span>
        <span>
            {{ 'login.passStyle' | translate }}
          </span>
      </small>
      <app-input [type]="'password'" [control]="inviteConfirmationForm.get('repeatPassword')" [label]="('login.repeatPass' | translate) + '*'"></app-input>
      <app-control-error [control]="inviteConfirmationForm"></app-control-error>

      <div class="flex flex-col pb-10">
        <div class="flex items-center mb-3">
          <mat-checkbox formControlName="terms" class="mb-1">
            <mat-label class="mr-1 link">{{'login.accept' | translate}}
              <a [href]="'assets/documents/'+lang.toUpperCase()+'/terms-and-conditions.pdf'" target="_blank" id="onboarding-terms-and-conditions" class="inline link mr-8">{{('login.termsConditions' | translate) + '*'}}</a>
            </mat-label>
          </mat-checkbox>
        </div>
        <app-control-error [control]="inviteConfirmationForm.get('terms')"></app-control-error>
      </div>

      <hr class="divider mr-8">

      <div class="fixed w-3/12 bottom-0 left-4 right-0 z-10 -ml-8 bg-grey-100">
        <hr class="divider mt-0 mb-4 mr-10">
        <div class="ml-8">
          <app-button-secondary [type]="'submit'" [disabled]="inviteConfirmationForm.invalid" class="mb-4">{{'buttons.continue' | translate}}</app-button-secondary>
          <a routerLink="/login" class="link mt-4 mb-10 block">{{'login.haveAnAccount' | translate}}</a>
        </div>
      </div>
    </form>
  </section>
</main>

