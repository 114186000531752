<main class="main main-background relative">
  <app-menu-locale [cornerPosition]="true"></app-menu-locale>
  <section class="h-full bg-grey-100 w-3/12">
    <img src="../../assets/img/logo.png" class="ml-8 mt-8"/>
    <h1 class="my-2 mx-8 overline-o1 primary-text">{{'login.title' | translate }}</h1>
    <hr class="divider w-full">
    <form [formGroup]="loginForm" (ngSubmit)="sendLogin()" class="flex flex-col px-8 py-4 relative">
      <app-input [type]="'text'" [control]="loginForm.get('email')" [label]="('login.email' | translate) + '*'" [placeholder]="'login.emailPlaceholder' | translate" data-test="email"></app-input>
      <app-input [type]="'password'" [control]="loginForm.get('password')" [label]="('login.pass' | translate) + '*'" data-test="password"></app-input>
      <a (click)="openRecoveryPassword()" class="link -mt-1 mb-2">{{'login.forgot' | translate}}</a>
      <hr class="divider w-full absolute block left-0 right-0 bottom-16">
      <app-button-secondary [type]="'submit'" class="mt-6" data-test="submit">{{'login.login' | translate}}</app-button-secondary>
    </form>
    <a routerLink="/onboarding" class="link block ml-8">{{'login.registration' | translate}}</a>
  </section>
</main>
